import React from "react";
import SectionHeader from "../../components/SectionHeader";
import {PlansTableWithTabs, Text} from "../../components";
import PlansTable from "../../components/PaidServices/PlansTable";

const Plans = ({
    description,
    plans,
    title,
    asteriskFootnote
}) => {
    return (
        <div className="plans-table">
            <SectionHeader
                title={title}
                isBlackTitle
                isCentered
            />

            <Text
                as="p"
                appearance="paragraph"
                className="plans-table__description"
            >
                {description}
            </Text>

            <div className="plans-table__desktop">
                <PlansTable plans={plans} />
            </div>

            <div className="plans-table__mobile">
                <PlansTableWithTabs plans={plans} />
            </div>

            {asteriskFootnote && asteriskFootnote.length && (
                <ul className="plans-table__asterisk-footnotes">
                    {asteriskFootnote.map(({fieldGroupName, note}, index) => (
                        <li
                            key={`${fieldGroupName}-${index}`}
                            className="plans-table__asterisk-footnote"
                            children={note}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
}

export default Plans;
