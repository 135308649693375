import React, {useMemo} from "react";
import {BlockWithHTML, BlockWithSections} from "./index";
import SectionHeader from "../../../components/SectionHeader";
import {Text} from "../../../components";
import Accordion from "../../../components/Accordion";
import cn from "classnames";

const Accordions = ({
    title,
    header,
    description,
    accordion: accordions,
}) => {

    const getAccordion = ({
        fieldGroupName,
        type,
        label,
        isNested,
        isSpaced,
        ...accordion
    }, index) => {
        const key = fieldGroupName + index;

        switch (type) {
            case 'html':
                return (
                    <Accordion
                        key={key}
                        title={label}
                        content={(
                            <BlockWithHTML {...accordion}/>
                        )}
                        blockClass="accordion-with-text"
                        chevronType='arrow'
                        className={cn(
                            isNested && "accordion-with-text--nested",
                            isSpaced && "accordion-with-text--spaced",
                        )}
                    />
                )
            case 'sections':
                return (
                    <Accordion
                        key={key}
                        title={label}
                        content={(
                            <BlockWithSections {...accordion} />
                        )}
                        blockClass="accordion-with-sections"
                        chevronType='arrow'
                        className={cn(
                            isNested && "accordion-with-sections--nested",
                            isSpaced && "accordion-with-text--spaced",
                        )}
                    />
                )
            default:
                return null;
        }
    }

    const getAccordions = useMemo(() => accordions.map(getAccordion), [accordions]);

    return (
        <div className="paid-accordions">
            <SectionHeader
                subtitle={header}
                title={title}
                isCentered
                isBlackTitle
            />

            {description
                ? (
                    <Text
                        as="p"
                        appearance="paragraph"
                        className="paid-accordions__description"
                        children={description}
                    />
                )
                : null
            }

            {getAccordions}
        </div>
    );
}

export default Accordions;
