import React from "react";
import {Text} from "../../components";

const OurServices = ({
    description,
    header,
    services,
    title,
}) => (
    <div className="our-services">
        {header
            ? (
                <Text
                    as="h2"
                    className="our-services__header section-subtitle"
                    children={header}
                />
            )
            : null
        }

        {title
            ? (
                <Text
                    as="h3"
                    className="our-services__title section-title section-title--black"
                    children={title}
                />
            )
            : null
        }

        {description
            ? (
                <Text
                    as="p"
                    appearance="paragraph"
                    className="our-services__description rte"
                >
                    <div dangerouslySetInnerHTML={{__html: description}} />
                </Text>
            )
            : null
        }

        <ul className="our-services__services">
            {services && services.length
                ? services.map(({
                    description,
                    icon,
                    name,
                }, index) => (
                    <li className="our-services__service" key={index}>
                        {icon
                            ? (
                                <span
                                    className="our-services__service-image"
                                    dangerouslySetInnerHTML={{__html: icon}}
                                />
                            )
                            : null
                        }

                        {name
                            ? (
                                <Text
                                    as="p"
                                    appearance="heading6"
                                    className="our-services__service-name"
                                    children={name}
                                />
                            )
                            : null
                        }

                        {description
                            ? (
                                <div
                                    className="our-services__service-description"
                                    dangerouslySetInnerHTML={{__html: description}}
                                />
                            )
                            : null
                        }
                    </li>
                ))
                : null
            }
        </ul>
    </div>
);

export default OurServices;
