import React from "react";
import DefaultHelmet from "../seo/DefaultHelmet";
import PageHeader from "../components/PageHeader";
import DefaultLayout from "../layouts/DefaultLayout";
import {
    TextWithText,
    Plans,
    OurServices,
    OurClients,
    Accordions,
} from "../sections/PaidServices";


const PaidServices = ({pageContext}) => {
    const paidService = pageContext.paidService;
    const paidServices = paidService?.paidServices;
    const pageHeader = paidService?.page_header;
    const metaData = paidServices?.metaData;
    const sections = paidServices?.sections;

    const pageTitle = metaData?.title || "Paid services";
    const pageDescription = metaData?.description || "Paid services description";

    const getSection = ({ fieldGroupName, ...section }, index) => {
        const key = fieldGroupName + index;

        switch (fieldGroupName) {
            case 'paidServices_Paidservices_Sections_TextWithText': {
                return (
                    <TextWithText
                        key={key}
                        {...section}
                    />
                );
            }
            case 'paidServices_Paidservices_Sections_Plans': {
                return (
                    <Plans
                        key={key}
                        {...section}
                    />
                );
            }
            case 'paidServices_Paidservices_Sections_OurServices': {
                return (
                    <OurServices
                        key={key}
                        {...section}
                    />
                );
            }
            case 'paidServices_Paidservices_Sections_OurClients': {
                return (
                    <OurClients
                        key={key}
                        {...section}
                    />
                );
            }
            case 'paidServices_Paidservices_Sections_Accordions': {
                return (
                    <Accordions
                        key={key}
                        {...section}
                    />
                );
            }

            default: {
                return null;
            }
        }
    };

    const getSections = () => {
        if (!Array.isArray(sections)) {
            return null;
        }

        return sections.map(getSection);
    }

    return (
        <DefaultLayout className="paid-services-page" isHeaderWithBlackText={true}>
            <DefaultHelmet title={pageTitle} description={pageDescription}/>

            {pageHeader && pageHeader.title && <PageHeader {...pageHeader} />}

            <div className="paid-services">
                {getSections()}
            </div>
        </DefaultLayout>
    );
}

export default PaidServices;
