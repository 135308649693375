import React from "react";
import {Text} from "../../../components";
import {GatsbyImage as Image, getImage} from "gatsby-plugin-image";
import cn from "classnames";

const SectionImage = ({ sectionImage }) => {
    const {
        altText,
        localFile
    } = sectionImage;

    const image = getImage(localFile);

    if (!image) {
        return null;
    }

    return (
        <Image
            image={image}
            alt={altText || ""}
            title={altText || ""}
            className="accordion-with-sections__section-image"
        />
    )
}

const BlockWithSections = ({ group: groups, title}) => (
    <>
        {title
            ? (
                <Text
                    as="h3"
                    appearance="heading5"
                    className="accordion-with-sections__content-title"
                    children={title}
                />
            )
            : null
        }

        {groups && groups.length && (
            <div className="accordion-with-sections__groups">
                {groups.map(({fieldGroupName, label, section: sections}, index) => {
                    let classModification;

                    switch (label) {
                        case "Header / Cart / Footer":
                        case "Popups / Cards / Slideshow":
                        case "Additional sections for high variability":
                            classModification = "accordion-with-sections__group-sections--center-desktop"
                            break;
                        default:
                            classModification = "";
                    }

                    return (
                        <div
                            key={`${fieldGroupName}-${index}:${label}`}
                            className="accordion-with-sections__group"
                        >
                            {label
                                ? (
                                    <Text
                                        as="h4"
                                        appearance="heading4"
                                        className="accordion-with-sections__group-title"
                                        children={label}
                                    />
                                )
                                : null
                            }

                            {sections && sections.length && (
                                <div className={cn(
                                    "accordion-with-sections__group-sections",
                                    classModification,
                                )}>
                                    {sections.map(({fieldGroupName, image, title}, index) => {
                                        let classModification;

                                        switch (label) {
                                            case "Header / Cart / Footer":
                                            case "Additional sections for high variability":
                                                classModification = "accordion-with-sections__section--3"
                                                break;
                                            default:
                                                classModification = "accordion-with-sections__section--4";
                                        }

                                        return (
                                            <div
                                                className={cn(
                                                    "accordion-with-sections__section",
                                                    classModification
                                                )}
                                                key={`${fieldGroupName}-${index}:${title}`}
                                            >
                                                {title
                                                    ? (
                                                        <Text
                                                            as="h4"
                                                            appearance="heading6"
                                                            className="accordion-with-sections__section-title"
                                                            children={title}
                                                        />
                                                    )
                                                    : null
                                                }

                                                <SectionImage sectionImage={image} />
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        )}
    </>
);

export default BlockWithSections;
