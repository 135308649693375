import React from "react";
import {Text} from "../../../components";

const BlockWithHTML = ({ text, title }) => (
    <>
        {title
            ? (
                <Text
                    as="h3"
                    appearance="heading5"
                    className="accordion-with-text__content-title"
                    children={title}
                />
            )
            : null
        }

        <div
            className="accordion-with-text__content-body"
            dangerouslySetInnerHTML={{__html: text}}
        />
    </>
);

export default BlockWithHTML;
