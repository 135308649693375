import React from "react";

const TextWithText = ({leftSide, rightSide}) => {
  return (
      <div className="text-with-text">
          {leftSide
              ? (
                  <div
                      className="text-with-text__col text-with-text__left-side"
                      dangerouslySetInnerHTML={{__html: leftSide}}
                  />
              )
              : null
          }

          {rightSide
              ? (
                  <div
                      className="text-with-text__col text-with-text__right-side"
                      dangerouslySetInnerHTML={{__html: rightSide}}
                  />
              )
              : null
          }
      </div>
  );
}

export default TextWithText;
