import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

const SectionHeader = ({
	title,
	subtitle,
	isBlackTitle = false,
	isCentered = false,
}) => {
	if(!title && !subtitle) {
		return null;
	}

	const getSectionTitle = () => {
		if(!title) {
			return null;
		}

		return (
			<h2 className={cn(
				"section-title",
				isBlackTitle && "section-title--black",
				isCentered && "section-title--centered",
			)}>
				{title}
			</h2>
		)
	};

	const getSectionSubtitle = () => {
		if(!subtitle) {
			return null;
		}

		return (
			<p className="section-subtitle">
				{subtitle}
			</p>
		)
	};

	return (
		<header className="section-header">
			{getSectionSubtitle()}

			{getSectionTitle()}
		</header>
	)
};

SectionHeader.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string
};

export default SectionHeader;
